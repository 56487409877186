import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/inclick/Foto.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/inclick/Foto2.png"

//logo de empresas clientes
import Inclick from "@components/pageCasosdeExito/image/logoscustomer/Inclick.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import campañas from "@components/pageCasosdeExito/image/iconcards/campañas.svg"
import gestion from "@components/pageCasosdeExito/image/iconcards/gestion.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/inclick/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/inclick/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/inclick/img3.svg"


const text1 = (
  <span className="">
    Inclick, tienda virtual especializada en la venta de productos de alta calidad en categorías como salud, hogar, cocina, belleza, fitness y tecnología; cuenta con distribución a nivel nacional y su mercado principal es el peruano. Su misión es brindar una experiencia de compra en línea confiable y segura, adaptada a las necesidades de cada cliente.
    <br /><br />
    Con Beex, Inclick ha incrementado en +82% la conversión de leads a ventas, optimizando la gestión de prospectos provenientes de sus campañas publicitarias. Su operación se ha modernizado, adoptando procesos automatizados que han incrementado la productividad y mejorado la experiencia del cliente.
  </span>
)

const text2 = (
  <span>
    Carlos Gonzáles, Gerente General de Inclick, ha liderado la integración de Beex en su operación de ecommerce, facilitando la automatización de su operación de televentas. Con más de 60 agentes en su equipo, Beex ha permitido optimizar la gestión de clientes y acelerar los procesos de conversión.
    <br /><br />
    Gracias a la integración de Beex Contact Center con su estrategia digital, Inclick ha optimizado la gestión de leads generados por anuncios en Google, Facebook, Instagram y TikTok. La automatización del primer contacto a través de WhatsApp ha permitido elevar la tasa de conversión en +82%, garantizando que los clientes reciban una respuesta inmediata y personalizada.
    <br /><br />
    El seguimiento de ventas mediante WhatsApp, junto con envíos masivos y respuestas automatizadas, ha permitido a Inclick agilizar el cierre de ventas y mejorar la experiencia del cliente, reduciendo el tiempo promedio de conversión en +70% y consolidando su modelo de ventas digitales.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “El uso de Beex ha fortalecido la comunicación con los clientes, permitiendo estrategias digitales más avanzadas y asegurando una atención rápida y personalizada a través de WhatsApp. Esto ha consolidado a Inclick como un referente en e-commerce y ventas digitales.”
  </p>
)

const text4 = ""


const text5 = (
  <span>
    El equipo de marketing de Inclick invierte en anuncios pagados para generar leads calificados. Gracias a la integración con Beex, estos leads son contactados de manera automatizada en el menor tiempo posible, asegurando un seguimiento eficiente.
    <br /><br />
    Gracias a Beex API Cloud, Inclick ha logrado fácilmente integrar su CRM con Beex Contact Center. Esto ha sido vital para hacer que el flujo y touchpoints de contacto con sus leads sea automatizado, rápido y eficiente.
    <br /><br />
    Sin duda, automatizar este flujo ha permitido optimizar el retorno de inversión publicitaria, reduciendo la pérdida de oportunidades y asegurando que cada lead reciba atención oportuna.
  </span>
)

const text6 = (
  <span>
    “Beex nos ha permitido maximizar la efectividad de nuestras campañas de anuncios en diferentes redes sociales, asegurando que cada prospecto sea atendido de inmediato. Esto ha incrementado nuestra tasa de conversión y mejorado nuestra estrategia comercial.”
  </span>
)

const text7 = (
  <span>
    WhatsApp se ha convertido en el canal principal de contacto de Inclick, facilitando la comunicación con los clientes en cada etapa del proceso de compra. Desde la confirmación del pedido hasta la coordinación de la entrega, Beex ha optimizado la gestión de ventas a través de este canal.
    <br /><br />
    Para un sector de tanto tráfico e interacciones, como el de ecommerce, tener automatizadas las acciones de contacto y comunicación es vital para estar cada que un lead lo necesita. Y no solo por la venta, sino por la post-venta, donde es ideal mantener un flujo de comunicación activo por WhatsApp o llamada.
    <br /><br />
    El uso de WhatsApp Business API ha permitido a Inclick agilizar sus interacciones con clientes, mejorar la atención y ofrecer un servicio más rápido y efectivo.

  </span>
)

const text8 = (
  <span>
    “WhatsApp se ha vuelto nuestro canal más importante para nuestras áreas de atención al cliente y ventas. Con Beex, podemos gestionar múltiples interacciones de manera ordenada, asegurando un mejor servicio y reduciendo el tiempo de conversión en +70%.”
  </span>
)
const text9 = (
  <span>
    El equipo de ventas de Inclick utiliza WhatsApp no solo para atender clientes en tiempo real, sino también para automatizar campañas de recontacto y envíos masivos de mensajes promocionales.
    <br /><br />
    Con Beex, han optimizado la comunicación con clientes recurrentes y nuevos prospectos, asegurando que cada interacción contribuya a fortalecer la relación con la marca y aumentar las conversiones.
  </span>
)

const text10 = (
  <span>
    “Las campañas automatizadas de WhatsApp nos han permitido mantener el contacto con nuestros clientes y potenciar nuestras ventas, logrando un crecimiento en tasa de recontacto de +65%.”
  </span>
)

const text11 = (
  <span>
    El crecimiento de Inclick en el comercio electrónico ha requerido un soporte confiable y un servicio que se adapte a sus necesidades en constante evolución. Beex ha proporcionado un soporte rápido y efectivo, garantizando estabilidad en la operación y una mejor experiencia para el equipo de ventas.
    <br /><br />
    La facilidad de uso de la plataforma y la rapidez del equipo de soporte han sido factores clave para el éxito de la integración.
  </span>
)

const text12 = (
  <span>
    “El soporte de Beex ha sido fundamental para optimizar nuestra operación. Su rapidez y acompañamiento nos han permitido mantenernos competitivos en el sector e-commerce, resolviendo incidencias en menos de 24 horas.”
  </span>
)
const text13 = (
  <span>
    Estos son algunos de los procesos con los que Beex ha ayudado a Inclick a mejorar su operación de televentas y atención al cliente, consolidándose como un aliado estratégico en su crecimiento digital.
  </span>
)

const data = {
  intro: {
    image: Inclick,
    link: "#",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: text4,
    title5: "Mejora en resultados de anuncios (Ads)",
    text5: text5,
    text6: text6,
    title7: "Uso de WhatsApp para e-commerce",
    text7: text7,
    fototwo: FotoKontigo2,
    text8: text8,
    title9: 'Automatización en campañas de marketing y ventas',
    text9: text9,
    text10: text10,
    title11: "Soporte y acompañamiento de Beex",
    text11: text11,
    text12: text12,
    text13: text13,

    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de atención al cliente, marketing y ventas. Beex ha permitido mejorar la comunicación con los clientes, asegurando una atención eficiente y personalizada."
    },
    {
      title: "Gestión multiagente",
      img: gestion,
      info: "Coordinación de más de 60 agentes con múltiples perfiles dentro de una sola plataforma de WhatsApp. Organización optimizada para responder de manera ágil y efectiva a cada cliente.",
    },
    {
      title: "Campañas masivas",
      img: campañas,
      info:
        "Automatización de envíos masivos de WhatsApp para promociones y recontacto con clientes. Beex ha permitido optimizar tiempos y mejorar la tasa de conversión."
    }
  ],
}

export default data
